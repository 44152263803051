<template>
  <div
    :style="{ 'background-color': blok?.backgroundColor || 'bg-white' }"
    :class="[{ 'relative z-[1]': !blok.backgroundColor }, 'color-canvas pt-14 pb-12']"
  >
    <img
      v-if="blok?.backgroundImage?.filename"
      :src="blok.backgroundImage.filename"
      class="absolute z-[-1] w-full h-full top-0 left-0 object-cover bg-cover bg-no-repeat"
    />
    <SectionWithMaxWidth>
      <div :class="[isFeaturesExists ? 'flex flex-col xl:flex-row px-[24px] sm:px-0' : 'flex flex-col']">
        <div class="flex flex-col px-[24px] medium:px-[24px] mobile-large:px-0 lg:px-0">
          <div
            :class="[
              isFeaturesExists ? 'md:w-full xl:w-[430px] sm:w-[260px] mobile-large:w-auto' : 'w-full',
              'text-heading-2 text-wrap',
            ]"
          >
            {{ blok.heading }}
          </div>
          <RichText v-if="blok.description" class="mt-4 mb-6" :text="blok.description" />
          <div
            v-if="isFeaturesExists"
            class="mt-6 inline-grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4"
          >
            <div
              v-for="feature of blok.features"
              :key="feature"
              data-test-id="home-page-developer-events-points"
              class="flex gap-2 items-center"
            >
              <VIcon name="check-circle-line" class="color-success-300 h-[22px] w-[22px] shrink-0" :size="-5" />
              <div data-test-id="home-page-developer-events-feature" class="text-base xl:text-xs">
                {{ feature }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow">
          <div
            data-test-id="developer-event-cards"
            :class="[
              !isFeaturesExists ? 'mt-0 md:grid-cols-2 xl:grid-cols-4' : 'mt-14 xl:mt-0  lg:grid-cols-3 md:grid-cols-2',
              'grid grid-cols-1 sm:grid-cols-2 gap-4 place-items-center md:place-items-stretch mb-6 xl:mb-0',
            ]"
          >
            <EventCard v-for="event in events" :key="event.id" :event-details="event.content" />
          </div>
          <div class="flex flex-col md:flex-row mt-4">
            <div class="flex md:hidden mt-4 mb-9 justify-center sm:justify-start">
              <NuxtLink href="/community">
                <VButton
                  class="vvd-theme-alternate"
                  size="condensed"
                  label="View All Events"
                  icon="arrow-right-line"
                  icon-trailing
                />
              </NuxtLink>
            </div>
            <div
              v-if="blok.socialLinks"
              class="grid grid-cols-3 sm:grid-cols-6 gap-[38px] mt-2 text-left md:text-center"
            >
              <EventSocialLinks v-for="socialLink in blok.socialLinks" :key="socialLink._uid" :link="socialLink" />
            </div>
            <div class="flex-grow" />
            <div class="hidden md:flex mt-2">
              <NuxtLink href="/events">
                <VButton
                  class="vvd-theme-alternate"
                  size="condensed"
                  label="View All Events"
                  icon="arrow-right-line"
                  icon-trailing
                />
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </SectionWithMaxWidth>
  </div>
</template>

<script setup lang="ts">
import { type PropType, defineProps, computed, ref, watch } from 'vue';
import { VButton, VIcon } from '@vonage/vivid-vue';
import type { Event, EventSection } from '@developer-portal/storyblok';
import RichText from '@/components/rich-text/RichText.vue';
import EventCard from '@/components/home/vonage/events/EventCard.vue';
import EventSocialLinks from '@/components/home/vonage/events/EventSocialLinks.vue';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';
import type { StoryblokStory } from 'storyblok-generate-ts';
import { useFetch } from 'nuxt/app';
import type { ISbStories } from '@storyblok/vue';
import resolve_relations from '@/storyblok/resolve_relations';
import moment from 'moment';

const props = defineProps({
  blok: { type: Object as PropType<EventSection>, required: true },
});

const events = ref<StoryblokStory<Event>[]>([]);

const isFeaturesExists = computed(() => {
  return props.blok.features && props.blok.features.length > 0;
});

const { data } = await useFetch<ISbStories>('/api/v1/portal/storyblok/stories', {
  method: 'POST',
  body: {
    by_slugs: 'branding/vonage/events/event-details/*',
    per_page: props?.blok?.eventCount ?? 3,
    page: 1,
    resolve_relations,
    filter_query: {
      endsAt: {
        gt_date: moment().format('YYYY-MM-DD HH:mm'),
      },
    },
    sort_by: 'content.startsAt:asc',
  },
});

async function updateEvents() {
  if (!data.value) {
    return;
  }
  events.value = data.value?.data?.stories ?? [];
}

watch(data, updateEvents, { immediate: true });
</script>
